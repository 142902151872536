import { useState } from 'react';
import { ShipmentType, ShipmentStatusType } from '../../types/Shipment';
import { findShipment } from '../../utils/findShipment';
import { useNavigate } from 'react-router-dom';

const AddShipment = () => {
  const generateRefNumber = () => {
    return Math.floor(Math.random() * 9999).toString();
  };

  const [shipment, setShipment] = useState({
    refNumber: generateRefNumber(),
    truckRegistered: false,
    implifiTruckNumMatch: false,
    ocrTrailerNumMatch: false,
    ocrLicenseNumMatch: false,
    checkedIn: false,
    inspected: false,
    dockConfirmation: null,
    shipperSignature: false,
    driverSignature: false,
    checkedOut: false,
    shipFrom: 'Detroit, MI',
    shipTo: 'Seattle, WA',
    estimatedShipTimeLocal: new Date().toString(),
    carrier: 'Central Transport',
    messages: [],
    photos: [],
    status: ShipmentStatusType.truckFound
  });

  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    await fetch('https://newlab-demo-api-48f138eaca60.herokuapp.com/shipments', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(shipment)
    }).catch((error) => {
      window.alert(error);
      return;
    });

    // const shipmentsFromStorage = JSON.parse(localStorage.getItem('shipments') || '[]');
    // shipmentsFromStorage.push(shipment);
    // localStorage.setItem('shipments', JSON.stringify(shipmentsFromStorage));
    navigate('/admin');
  };

  const handleCarrierChange = (e: any) => {
    const newShipment = { ...shipment };
    newShipment.carrier = e.target.value;
    setShipment(newShipment);
  };

  const handleShipFromChange = (e: any) => {
    const newShipment = { ...shipment };
    newShipment.shipFrom = e.target.value;
    setShipment(newShipment);
  };

  const handleShipToChange = (e: any) => {
    const newShipment = { ...shipment };
    newShipment.shipTo = e.target.value;
    setShipment(newShipment);
  };

  return (
    <div className="p-5">
      <h1>Add Shipment</h1>
      <h2 className="mb-2">Ref # {shipment.refNumber}</h2>
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <label>
          Carrier:{' '}
          <input
            type="text"
            placeholder="Carrier"
            value={shipment.carrier}
            onChange={handleCarrierChange}
          />
        </label>
        <label>
          Ship From:{' '}
          <input
            type="text"
            placeholder="Ship From"
            value={shipment.shipFrom}
            onChange={handleShipFromChange}
          />
        </label>
        <label>
          Ship To:{' '}
          <input
            type="text"
            placeholder="Ship To"
            value={shipment.shipTo}
            onChange={handleShipToChange}
          />
        </label>
        <button
          className="ml-auto bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
          type="submit"
        >
          Create Shipment
        </button>
      </form>
    </div>
  );
};

export default AddShipment;
