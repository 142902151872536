import { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { findShipment } from '../../utils/findShipment';
import getBase64Image from '../../utils/getBase64Image';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {
  ShipmentMessageType,
  ShipmentPhotoType,
  ShipmentType
} from '../../types/Shipment';
import { shipments } from '../../db';

const UpdateShipment = () => {
  const [editing, setEditing] = useState('');
  const [message, setMessage] = useState<ShipmentMessageType>();
  const [photo, setPhoto] = useState<any>();
  const [shipment, setShipment] = useState<any>();
  const shipmentId = useParams<{ shipmentId: string }>().shipmentId;
  const navigate = useNavigate();
  const reader = new FileReader();

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setEditing('');
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    if (!shipmentId) {
      return;
    }

    const fetchData = async () => {
      const data = await fetch(
        `https://newlab-demo-api-48f138eaca60.herokuapp.com/shipments/${shipmentId}`
      );
      const json = await data.json();
      setShipment(json);
    };
    fetchData();
  }, [shipmentId]);

  if (!shipment) {
    return <div>Sorry, shipment not found!</div>;
  }

  const handleBooleanClick = (key: string) => {
    const newShipment = { ...shipment };
    newShipment[key] = !newShipment[key];
    setShipment(newShipment);
  };

  const handleTextClick = (key: string) => {
    if (editing === key) {
      setEditing('');
    } else {
      setEditing(key);
    }
  };

  const handleChange = (e: any) => {
    const newShipment = { ...shipment };
    newShipment[editing] = e.target.value;
    setShipment(newShipment);
  };

  const handleMessageClick = () => {
    setMessage({
      id: Math.floor(Math.random() * 9999).toString(),
      body: '',
      createdAt: new Date().toString()
    });
    setEditing('messages');
  };

  const handleAddMessage = () => {
    const newShipment = { ...shipment };
    const messages = newShipment.messages;
    messages.push(message);
    setMessage(undefined);
    setEditing('');
  };

  const handlePhotoClick = () => {
    setEditing('photos');
    setPhoto({
      id: Math.floor(Math.random() * 9999).toString(),
      createdAt: new Date().toString(),
      url: ''
    });
  };

  const handlePhotoChange = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await getBase64Image(file);
    const newPhoto = { ...photo, url: base64 };
    setPhoto(newPhoto);
  };

  const handleAddPhoto = () => {
    const newShipment = { ...shipment };

    if (photo.url.length) {
      newShipment.photos.push(photo);
      setShipment(newShipment);
      setPhoto(undefined);
      setEditing('');
    }
  };

  const handleUpdate = async () => {
    await fetch(
      `https://newlab-demo-api-48f138eaca60.herokuapp.com/shipments/${shipmentId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(shipment),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    navigate('/admin');
    // const shipmentsFromStorage = localStorage.getItem('shipments');
    // if (shipmentsFromStorage) {
    //   const shipments = JSON.parse(shipmentsFromStorage);
    //   shipments.find((s: ShipmentType, i: number) => {
    //     if (s.refNumber === shipmentId) {
    //       shipments[i] = shipment;
    //     }
    //   });
    //   localStorage.setItem('shipments', JSON.stringify(shipments));
    //   navigate('/admin');
    // }
  };

  return (
    <div className="p-5 flex flex-col">
      <h1 className="mb-4">Update Shipment: {shipment.refNumber}</h1>
      <table className="bg-[#FBFBFB] table-auto border-slate-300 border-[1px]">
        <thead className="border-red-400 border-b-[3px]">
          <tr>
            <th className="p-2">Ref #</th>
            <th className="p-2">Truck Registered</th>
            <th className="p-2">Implifi Truck #</th>
            <th className="p-2">OCR Trailer #</th>
            {/* <th className="p-2">OCR License #</th> */}
            <th className="p-2 whitespace-nowrap" title="Checked In">
              C-I
            </th>
            <th className="p-2" title="Inspected">
              Insp.
            </th>
            <th className="p-2" title="Drop Spot #">
              Dock Assign
            </th>
            <th className="p-2" title="Shipper Signature">
              Ship Sig
            </th>
            <th className="p-2" title="Driver Signature">
              Drv Sig
            </th>
            <th className="p-2 whitespace-nowrap" title="Checked Out">
              C-O
            </th>
            <th className="p-2">Ship From</th>
            <th className="p-2">Ship To</th>
            <th className="p-2">Est. Ship Date/Time (Local Time)</th>
            <th className="p-2">Carrier</th>
            <th className="p-2" title="Message">
              MSG
            </th>
            <th className="p-2">Photo</th>
          </tr>
        </thead>
        <tbody className="text-center">
          <tr className="border-slate-200 border-b-0" ref={wrapperRef}>
            <td className="p-2">{shipment.refNumber}</td>
            <td
              className="p-2 cursor-pointer hover:bg-[#f5f5f5]"
              onClick={() => handleBooleanClick('truckRegistered')}
            >
              {shipment.truckRegistered ? (
                <CheckIcon className="text-green-500" />
              ) : (
                <ClearIcon className="text-red-400" />
              )}
            </td>
            <td
              className="p-2 cursor-pointer hover:bg-[#f5f5f5]"
              onClick={() => handleBooleanClick('implifiTruckNumMatch')}
            >
              {shipment.implifiTruckNumMatch ? (
                <CheckIcon className="text-green-500" />
              ) : null}
            </td>
            <td
              className="p-2 cursor-pointer hover:bg-[#f5f5f5]"
              onClick={() => handleBooleanClick('ocrTrailerNumMatch')}
            >
              {shipment.ocrTrailerNumMatch ? (
                <CheckIcon className="text-green-500" />
              ) : null}
            </td>
            {/* <td
              className="p-2 cursor-pointer hover:bg-[#f5f5f5]"
              onClick={() => handleBooleanClick('ocrLicenseNumMatch')}
            >
              {shipment.ocrLicenseNumMatch ? (
                <CheckIcon className="text-green-500" />
              ) : null}
            </td> */}
            <td
              className="p-2 cursor-pointer hover:bg-[#f5f5f5]"
              onClick={() => handleBooleanClick('checkedIn')}
            >
              {shipment.checkedIn ? <CheckIcon className="text-green-500" /> : null}
            </td>
            <td
              className="p-2 cursor-pointer hover:bg-[#f5f5f5]"
              onClick={() => handleBooleanClick('inspected')}
            >
              {shipment.inspected ? <CheckIcon className="text-green-500" /> : null}
            </td>
            <td
              className="p-2 cursor-pointer hover:bg-[#f5f5f5]"
              onClick={() => handleTextClick('dockConfirmation')}
            >
              {editing === 'dockConfirmation' ? (
                <input
                  type="text"
                  value={shipment.dockConfirmation || ''}
                  onChange={handleChange}
                  autoFocus
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              ) : shipment.dockConfirmation ? (
                <span className="text-green-500">{shipment.dockConfirmation}</span>
              ) : null}
            </td>
            <td
              className="p-2 cursor-pointer hover:bg-[#f5f5f5]"
              onClick={() => handleBooleanClick('shipperSignature')}
            >
              {shipment.shipperSignature ? (
                <CheckIcon className="text-green-500" />
              ) : null}
            </td>
            <td
              className="p-2 cursor-pointer hover:bg-[#f5f5f5]"
              onClick={() => handleBooleanClick('driverSignature')}
            >
              {shipment.driverSignature ? <CheckIcon className="text-green-500" /> : null}
            </td>
            <td
              className="p-2 cursor-pointer hover:bg-[#f5f5f5]"
              onClick={() => handleBooleanClick('checkedOut')}
            >
              {shipment.checkedOut ? <CheckIcon className="text-green-500" /> : null}
            </td>
            <td
              className="p-2 cursor-pointer hover:bg-[#f5f5f5]"
              onClick={() => handleTextClick('shipFrom')}
            >
              {editing === 'shipFrom' ? (
                <input
                  type="text"
                  value={shipment.shipFrom}
                  onChange={handleChange}
                  autoFocus
                  onClick={(e) => e.stopPropagation()}
                />
              ) : (
                shipment.shipFrom
              )}
            </td>
            <td
              className="p-2 cursor-pointer hover:bg-[#f5f5f5]"
              onClick={() => handleTextClick('shipTo')}
            >
              {editing === 'shipTo' ? (
                <input
                  type="text"
                  value={shipment.shipTo}
                  onChange={handleChange}
                  autoFocus
                  onClick={(e) => e.stopPropagation()}
                />
              ) : (
                shipment.shipTo
              )}
            </td>
            <td className="p-2 cursor-pointer hover:bg-[#f5f5f5]">
              {new Date(shipment.estimatedShipTimeLocal).toLocaleString('en-us')}
            </td>
            <td
              className="p-2 cursor-pointer hover:bg-[#f5f5f5]"
              onClick={() => handleTextClick('carrier')}
            >
              {editing === 'carrier' ? (
                <input
                  type="text"
                  value={shipment.carrier}
                  onChange={handleChange}
                  autoFocus
                  onClick={(e) => e.stopPropagation()}
                />
              ) : (
                shipment.carrier
              )}
            </td>
            <td
              className="p-2 cursor-pointer hover:bg-[#f5f5f5]"
              onClick={handleMessageClick}
            >
              {editing === 'messages' && message ? (
                <div>
                  <textarea
                    placeholder="New Message"
                    autoFocus
                    value={message?.body || ''}
                    onChange={(e) => setMessage({ ...message, body: e.target.value })}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <button
                    className="mt-4 ml-auto bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddMessage();
                    }}
                  >
                    Add Message
                  </button>
                </div>
              ) : (
                shipment.messages?.length
              )}
            </td>
            <td
              className="p-2 cursor-pointer hover:bg-[#f5f5f5]"
              onClick={handlePhotoClick}
            >
              {editing === 'photos' && photo ? (
                <div>
                  <input type="file" id="uploadPhoto" onChange={handlePhotoChange} />
                  <button
                    className="mt-4 ml-auto bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddPhoto();
                    }}
                  >
                    Add Photo
                  </button>
                </div>
              ) : (
                shipment.photos?.length
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <button
        className="mt-4 ml-auto bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
        onClick={handleUpdate}
      >
        Update Shipment
      </button>
    </div>
  );
};

export default UpdateShipment;
