import { useContext, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { UserContext } from '../../App';
import { logout } from '../../services/authService';

interface NavBarProps {
  toggleSidePanel: () => void;
}

const NavBar = ({ toggleSidePanel }: NavBarProps) => {
  const userContext = useContext(UserContext);
  // @ts-ignore
  const { user } = userContext;
  const [title, setTitle] = useState<string>('Shipments');

  const handleLogout = () => {
    logout();
    window.location.reload();
  };

  function useLocationEffect(callback: (location?: Location) => any) {
    const location = useLocation();

    useEffect(() => {
      // @ts-ignore
      callback(location);
    }, [location, callback]);
  }

  // @ts-ignore
  useLocationEffect((location: Location) =>
    setTitle(location.pathname.split('/')[1] || 'Shipments')
  );

  return (
    <div className="flex bg-white items-center shadow h-[67px] pl-5">
      <div className="flex items-center cursor-pointer" onClick={toggleSidePanel}>
        <MenuOpenIcon className="mr-2" />
        <h3 className="text-xl capitalize">{title}</h3>
      </div>
      <div className="ml-auto mr-7 text-sm">
        <span>
          {/* @ts-ignore */}
          Logged in as{' '}
          <Link to="/account" className="text-sky-600 hover:underline">
            {user.email}
          </Link>{' '}
          -{' '}
          <button className="text-sky-600 hover:underline" onClick={handleLogout}>
            Logout
          </button>
        </span>
      </div>
    </div>
  );
};

export default NavBar;
