import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../App';
import { Modal } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import Select from 'react-select';
import { twMerge } from 'tailwind-merge';
const READ_API_URL = process.env.REACT_APP_READ_API_URL;
const WRITE_API_URL = process.env.REACT_APP_WRITE_API_URL;

const User = () => {
  // @ts-ignore
  const { user } = useContext(UserContext);
  let userId = useParams<{ userId: string }>().userId;
  const location = useLocation();
  const { pathname } = location;
  const [userDetail, setUserDetail] = useState<any | null>(null);
  const [showFacilitiesModal, setShowFacilitiesModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [facilitySearchString, setFacilitySearchString] = useState('');
  const [facilities, setFacilities] = useState<any>([]);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [activateLoading, setActivateLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [roles, setRoles] = useState<any>([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [updatedUser, setUpdatedUser] = useState({
    name: '',
    role: null,
    facilities: []
  });
  const [isDiff, setIsDiff] = useState(false);
  const [loading, setLoading] = useState(true);
  let viewingOwnAccount = false;

  if (pathname === '/account' && !!user) {
    viewingOwnAccount = true;
    userId = user.id;
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${READ_API_URL}/api/v1/admin/users/${userId}`);
      setUserDetail(response.data.user);
      const facilitiesResponse = await axios.get(
        `${READ_API_URL}/api/v1/admin/users/facilities`
      );

      // Combine user and admin facilities and remove duplicates
      const userFacilities = response.data.user.facilities;
      const adminFacilities = facilitiesResponse.data.facilities;
      const combinedFacilities = userFacilities.concat(adminFacilities);
      const uniqueFacilities = combinedFacilities.reduce((acc: any, facility: any) => {
        if (!acc.find((f: any) => f.id === facility.id)) {
          acc.push(facility);
        }
        return acc;
      }, []);
      setFacilities(uniqueFacilities);

      if (!viewingOwnAccount) {
        const rolesResponse = await axios.get(`${READ_API_URL}/api/v1/admin/users/roles`);
        const roleOptions = rolesResponse.data.roles.map((role: any) => ({
          value: role.id,
          label: role.name
        }));
        setRoles(roleOptions);
      }
      setLoading(false);
    };

    if (!!user) {
      fetchData();
    }
  }, [user, userId, viewingOwnAccount]);

  useEffect(() => {
    if (!userDetail) {
      return;
    }

    const filteredFacilities = userDetail.facilities.filter(
      (facility: any) =>
        facility.name.toLowerCase().includes(facilitySearchString.toLowerCase()) ||
        facility.id.toString().includes(facilitySearchString)
    );
    setFacilities(filteredFacilities);
  }, [facilitySearchString, userDetail]);

  useEffect(() => {
    if (!userDetail || !updatedUser) {
      return;
    }
    const compareUserDiff = () => {
      if (userDetail.username !== updatedUser.name) {
        return true;
      }
      if (userDetail.role_id !== updatedUser.role) {
        return true;
      }

      const userFacilities = userDetail.facilities
        .map((facility: any) => facility.id)
        .sort();
      const updatedFacilities = updatedUser.facilities.sort();

      if (userFacilities.length !== updatedFacilities.length) {
        return true;
      } else {
        for (let i = 0; i < userFacilities.length; i++) {
          if (userFacilities[i] !== updatedFacilities[i]) {
            return true;
          }
        }
      }

      return false;
    };

    setIsDiff(compareUserDiff());
  }, [updatedUser, userDetail]);

  if (loading) {
    return (
      <div className="p-5">
        {loading && (
          <div className="flex flex-col items-center mt-4">
            <CircularProgress />
            <p className="mt-2">Loading...</p>
          </div>
        )}
      </div>
    );
  }

  if (!userDetail) {
    return <div>User Not Found</div>;
  }
  const { email, username, role_name } = userDetail;

  const handleDeactivateClick = async () => {
    try {
      setActivateLoading(true);
      await axios.put(`${WRITE_API_URL}/api/v1/admin/users/${userId}/deactivate`);
      setUserDetail({ ...userDetail, approved: false });
      setActivateLoading(false);
    } catch (error) {
      console.error('Error deactivating user', error);
    }
  };

  const handleReactivateClick = async () => {
    try {
      setActivateLoading(true);
      await axios.put(`${WRITE_API_URL}/api/v1/admin/users/${userId}/reactivate`);
      setUserDetail({ ...userDetail, approved: true });
      setActivateLoading(false);
    } catch (error) {
      console.error('Error reactivating user', error);
    }
  };

  const handleUpdateUserButtonClick = () => {
    setUpdatedUser({
      name: userDetail.username,
      role: userDetail.role_id,
      facilities: userDetail.facilities.map((facility: any) => facility.id)
    });

    setShowUpdateModal(true);
  };

  const handleResetPasswordClick = async () => {
    setResetPasswordLoading(true);
    try {
      await axios.post(
        `${WRITE_API_URL?.replace('web-btb', 'api-btb')}/api/v1/admin/send_reset_email`,
        { email: email }
      );

      setEmailSent(true);
      setResetPasswordLoading(false);
      setError('');
    } catch (error) {
      console.error('Error sending reset email', error);
      setError('Error sending reset email');
      setResetPasswordLoading(false);
    }
  };

  const handleFacilitiesModalClose = () => {
    setShowFacilitiesModal(false);
    setFacilitySearchString('');
  };

  const handleUpdateModalClose = () => {
    setShowUpdateModal(false);
    setSuccess('');
    setError('');
  };

  const handleUpdateUser = async (e: any) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${WRITE_API_URL}/api/v1/admin/users/${userId}`,
        updatedUser
      );
      setSuccess(response.data.message);
    } catch (error) {
      console.error('Error updating user', error);
      setError('Error updating user');
    }
  };

  const handleUpdateUserChange = (e: any) => {
    setUpdatedUser({ ...updatedUser, [e.target.id]: e.target.value });
  };

  const handleFacilitySearchChange = (e: any) => {
    setFacilitySearchString(e.target.value);
  };

  const facilitiesOptions = facilities.map((facility: any) => ({
    value: facility.id,
    label: `(${facility.id}) ${facility.name}`
  }));

  return (
    <div className="p-5">
      <div className="shadow bg-[#FBFBFB] p-3">
        <h1 className="font-bold text-xl mb-3">User Info:</h1>
        <p>
          <span className="font-bold">Email: </span>
          {email}
        </p>
        <p>
          <span className="font-bold">Name: </span>
          {username}
        </p>
        <p>
          <span className="font-bold">Role: </span>
          {role_name}
        </p>
        <div>
          <p className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
            <span className="font-bold">Facilities: </span>
            {userDetail.facilities
              .map((facility: any) => `(${facility.id}) ${facility.name}`)
              .join(', ')}
          </p>
          <button
            className="bg-sky-600 text-white py-1 px-2 rounded-md mt-1"
            onClick={() => setShowFacilitiesModal(true)}
          >
            Show All Facilities
          </button>
        </div>
      </div>
      <div>
        <h1 className="font-bold text-lg mt-5 mb-3">Actions:</h1>
        <div className="flex gap-3">
          {!viewingOwnAccount &&
            (!!userDetail?.approved ? (
              <button
                className="bg-red-600 text-white py-2 px-4 rounded-md"
                onClick={handleDeactivateClick}
                disabled={activateLoading}
              >
                {!!activateLoading ? (
                  <>
                    Deactivating User
                    <CircularProgress
                      style={{
                        color: 'white',
                        height: '15px',
                        width: '15px',
                        marginLeft: '4px'
                      }}
                    />
                  </>
                ) : (
                  'Deactivate User'
                )}
              </button>
            ) : (
              <button
                className="bg-green-600 text-white py-2 px-4 rounded-md"
                onClick={handleReactivateClick}
                disabled={activateLoading}
              >
                {!!activateLoading ? (
                  <>
                    Reactivating User
                    <CircularProgress
                      style={{
                        color: 'white',
                        height: '15px',
                        width: '15px',
                        marginLeft: '4px'
                      }}
                    />
                  </>
                ) : (
                  'Reactivate User'
                )}
              </button>
            ))}
          <button
            className="bg-sky-600 text-white py-2 px-4 rounded-md"
            onClick={handleUpdateUserButtonClick}
          >
            Update User
          </button>
          {emailSent ? (
            <p className="text-green-500">Password reset email sent</p>
          ) : (
            <button
              className="bg-sky-600 text-white py-2 px-4 rounded-md"
              onClick={handleResetPasswordClick}
              disabled={resetPasswordLoading}
            >
              {!!resetPasswordLoading ? (
                <>
                  Sending
                  <CircularProgress
                    style={{
                      color: 'white',
                      height: '15px',
                      width: '15px',
                      marginLeft: '4px'
                    }}
                  />
                </>
              ) : (
                'Send Reset Password Email'
              )}
            </button>
          )}
        </div>
        {!!error && <p className="text-red-500">{error}</p>}
      </div>
      <Modal
        open={showFacilitiesModal}
        onClose={handleFacilitiesModalClose}
        className="flex justify-center items-center"
      >
        <div className="w-1/2 bg-white border p-5 h-2/3 relative overflow-auto">
          <Close
            className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
            onClick={handleFacilitiesModalClose}
          />
          <h1 className="font-bold mb-2">All Facilities</h1>
          Search:
          <input
            type="search"
            className="border-slate-300 border-[1px] p-1 h-[38px] rounded ml-2"
            onChange={handleFacilitySearchChange}
            value={facilitySearchString}
            placeholder="Search"
          />
          <div>
            {facilities.map((facility: any) => (
              <div key={facility.id} className="p-1">
                {`(${facility.id}) ${facility.name}`}
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <Modal
        open={showUpdateModal}
        onClose={handleUpdateModalClose}
        className="flex justify-center items-center"
      >
        <div className="w-1/2 bg-white border p-5 h-2/3 relative overflow-y-scroll">
          <Close
            className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
            onClick={handleUpdateModalClose}
          />
          {!!success ? (
            <div className="flex flex-col items-center justify-center h-full">
              <Check className="text-green-500" />
              <p className="text-green-500 text-lg">{success}</p>
            </div>
          ) : (
            <form onSubmit={handleUpdateUser}>
              <div>
                <h1 className="font-bold text-lg mb-2">New User</h1>
                {!!error && (
                  <p className="text-red-500 bg-red-100 border border-red-500 px-2 my-2 capitalize">
                    {error}
                  </p>
                )}
                <div className="flex flex-col mb-2">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    className="border-slate-300 border-[1px] p-1 h-[38px] rounded"
                    placeholder="Name"
                    onChange={handleUpdateUserChange}
                    value={updatedUser.name}
                    required
                  />
                </div>
                {!viewingOwnAccount && (
                  <>
                    <div className="flex flex-col mb-2">
                      <label htmlFor="role">Role</label>
                      <Select
                        options={roles}
                        placeholder="Role"
                        className="min-w-40"
                        onChange={(selectedOption: any) => {
                          setUpdatedUser({
                            ...updatedUser,
                            role: selectedOption.value
                          });
                        }}
                        // @ts-ignore
                        value={roles.find((role: any) => role.value === updatedUser.role)}
                        isSearchable={false}
                        required
                      />
                    </div>
                    <div className="flex flex-col mb-2">
                      <label htmlFor="facilities">Facilities</label>
                      <Select
                        options={facilitiesOptions}
                        isSearchable
                        isMulti
                        placeholder="Facilities"
                        className="min-w-40"
                        onChange={(selectedOptions: any) => {
                          setUpdatedUser({
                            ...updatedUser,
                            facilities: selectedOptions.map((option: any) => option.value)
                          });
                        }}
                        value={facilitiesOptions.filter((option: any) => {
                          // @ts-ignore
                          return updatedUser.facilities.includes(option.value);
                        })}
                        required
                      />
                    </div>
                  </>
                )}
                <button
                  className={twMerge(
                    'bg-sky-600 text-white p-2 rounded hover:bg-sky-500',
                    !isDiff && 'bg-gray-300 hover:bg-gray-300'
                  )}
                  type="submit"
                  disabled={!isDiff}
                >
                  Update
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default User;
