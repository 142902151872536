import axios from 'axios';
import * as jose from 'jose';
const READ_API_URL = process.env.REACT_APP_READ_API_URL;
const WRITE_API_URL = process.env.REACT_APP_WRITE_API_URL;

const setAuthToken = (token) => {
  if (token) {
    const { exp } = jose.decodeJwt(token);
    const expiry = new Date(exp * 1000).toUTCString();
    document.cookie = `authToken=${token}; expires=${expiry}; path=/; Secure; SameSite=Strict;`;
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

const login = async (credentials) => {
  try {
    const response = await axios.post(`${WRITE_API_URL}/api/v1/admin/login`, credentials);
    const { token, email, id } = response.data;
    const user = {
      id: id,
      name: '',
      roles: ['admin'],
      email: email
    };

    setAuthToken(token);
    const userManagementResponse = await axios.get(
      `${READ_API_URL}/api/v1/admin/can_access_user_management`
    );
    user.canAccessUserManagement = userManagementResponse.data.result;
    localStorage.setItem('user', JSON.stringify(user));

    return user;
    // return jwt_decode(token); // Decode and return user information
  } catch (error) {
    console.error('Login failed', error);
    throw error;
  }
};

const logout = () => {
  document.cookie = 'authToken=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  localStorage.removeItem('user');
  setAuthToken(null);
};

const validateToken = async (token) => {
  //   // Find a better way, but attempt to retrieve users for now
  //   const response = await axios.get('http://localhost:9000/api/v1/admin/users');
  //   if (response.status === 401) {
  //     localStorage.removeItem('authToken');
  //   } else {
  //     return true;
  //   }
};

export { login, logout, validateToken };

// const login = async (credentials) => {
//     try {
//       const response = await axios.post(`${API_URL}/api/v1/admin/login`, credentials);
//       const { token, requires2FA } = response.data;

//       if (requires2FA) {
//         // Handle the case where 2FA is required
//         // You might redirect the user to a 2FA verification page
//       } else {
//         setAuthToken(token);
//         return jwt_decode(token);
//       }
//     } catch (error) {
//       console.error('Login failed', error);
//       throw error;
//     }
//   };

//   const verify2FA = async (code) => {
//     try {
//       const response = await axios.post(`${API_URL}/api/v1/admin/verify-2fa`, { code });
//       const { token } = response.data;
//       setAuthToken(token);
//       return jwt_decode(token);
//     } catch (error) {
//       console.error('2FA verification failed', error);
//       throw error;
//     }
//   };

// const authenticateWithSSO = async () => {
//     try {
//       const response = await axios.get(`${SSO_PROVIDER_URL}/auth`);
//       const { ssoToken } = response.data;
//       // Store or use the received SSO token
//       return ssoToken;
//     } catch (error) {
//       console.error('SSO authentication failed', error);
//       throw error;
//     }
//   };

// const useSSOToken = async (ssoToken) => {
//     try {
//       const response = await axios.post(`${API_URL}/api/v1/admin/use-sso-token`, { ssoToken });
//       const { token } = response.data;
//       setAuthToken(token);
//       return jwt_decode(token);
//     } catch (error) {
//       console.error('Failed to use SSO token', error);
//       throw error;
//     }
//   };
