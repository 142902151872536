import { useState } from 'react';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../App';
import { login } from '../../services/authService';
import axios from 'axios';
const WRITE_API_URL = process.env.REACT_APP_WRITE_API_URL;

const Login = () => {
  // @ts-ignore
  const { user, setUser } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);

  if (!!user) {
    return <Navigate to="/shipments" replace />;
  }

  const handleLogin = async () => {
    try {
      const loginResponse = await login({ email, password });
      setUser(loginResponse);
      // Redirect or update state to reflect the authenticated state
    } catch (error) {
      console.error('Login failed', error);
      setError('Invalid email or password');
    }
  };

  return (
    <div className="flex grow items-center justify-center h-full -ml-48">
      <div className="flex flex-col gap-4 bg-white p-8 shadow">
        {forgotPassword ? (
          <ForgotPasswordForm
            email={email}
            setEmail={setEmail}
            setForgotPassword={setForgotPassword}
          />
        ) : (
          <>
            <h1 className="text-center">Welcome Back</h1>
            <input
              className="border border-gray-400 p-2 bg-[#FBFBFB]"
              type="text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <input
              className="border border-gray-400 p-2 bg-[#FBFBFB]"
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            {!!error && <p className="text-red-500">{error}</p>}
            <button
              onClick={() => setForgotPassword(true)}
              className="text-sm hover:underline text-left"
            >
              Forgot Password?
            </button>
            <button
              className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
              onClick={handleLogin}
            >
              Login
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const ForgotPasswordForm = ({
  email,
  setEmail,
  setForgotPassword
}: {
  email: string;
  setEmail: (e: string) => void;
  setForgotPassword: (e: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState('');

  const handleForgotPasswordClick = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${WRITE_API_URL?.replace('web-btb', 'api-btb')}/api/v1/admin/send_reset_email`,
        { email: email }
      );

      setEmailSent(true);
      setLoading(false);
      setError('');
    } catch (error) {
      console.error('Error sending reset email', error);
      setError('Error sending reset email');
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-center">Forgot Your Password?</h1>
      {emailSent ? (
        <p className="text-green-500">Password reset email sent</p>
      ) : (
        <>
          <label className="text-sm">Email:</label>
          <input
            className="border border-gray-400 p-2 bg-[#FBFBFB]"
            type="text"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <button
            className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
            onClick={handleForgotPasswordClick}
            disabled={loading}
          >
            {!!loading ? 'Sending...' : 'Send Reset Email'}
          </button>
        </>
      )}
      {!!error && <p className="text-red-500">{error}</p>}
      <button
        className="text-sky-600 text-right hover:underline hover:text-sky-500"
        onClick={() => setForgotPassword(false)}
      >
        Login
      </button>
    </div>
  );
};

export default Login;
