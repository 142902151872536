import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../App';
import Select from 'react-select';
import { useLinkClickHandler } from 'react-router-dom';
import axios from 'axios';
import { Modal } from '@mui/material';
import { Close, Check } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { twMerge } from 'tailwind-merge';
const READ_API_URL = process.env.REACT_APP_READ_API_URL;
const WRITE_API_URL = process.env.REACT_APP_WRITE_API_URL;

const Users = () => {
  // @ts-ignore
  const { user } = useContext(UserContext);
  const [users, setUsers] = useState<any>([]);
  const [searchedUsers, setSearchedUsers] = useState<any>([]);
  const [searchString, setSearchString] = useState('');
  //   const [createdAt, setCreatedAt] = useState<string>('');
  const [facilities, setFacilities] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);
  const [selectedFacilities, setSelectedFacilities] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [newUser, setNewUser] = useState({
    email: '',
    name: '',
    password: '',
    passwordConfirmation: '',
    role: '',
    facilities: []
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${READ_API_URL}/api/v1/admin/users`);
      setUsers(response.data.users);
      const facilitiesResponse = await axios.get(
        `${READ_API_URL}/api/v1/admin/users/facilities`
      );
      const facilityOptions = facilitiesResponse.data.facilities.map((facility: any) => ({
        value: facility.id,
        label: `(${facility.id}) ${facility.name}`
      }));
      setFacilities(facilityOptions);
      const rolesResponse = await axios.get(`${READ_API_URL}/api/v1/admin/users/roles`);
      const roleOptions = rolesResponse.data.roles.map((role: any) => ({
        value: role.id,
        label: role.name
      }));
      setRoles(roleOptions);
      setLoading(false);
    };

    if (!!user) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    if (!searchString && !selectedFacilities.length) {
      setSearchedUsers(users);
      return;
    } else {
      let searchedUsers = users;

      if (selectedFacilities.length) {
        searchedUsers = users.filter((user: any) => {
          return user.facilities.some((facility: any) => {
            return selectedFacilities.includes(facility.id);
          });
        });
      }

      searchedUsers = searchedUsers.filter((user: any) => {
        return (
          user.email?.toLowerCase().includes(searchString.toLowerCase()) ||
          user.username?.toLowerCase().includes(searchString.toLowerCase())
        );
      });
      setSearchedUsers(searchedUsers);
    }
  }, [searchString, users, selectedFacilities]);

  const handleSearchChange = (e: any) => {
    setSearchString(e.target.value);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setNewUser({
      email: '',
      name: '',
      password: '',
      passwordConfirmation: '',
      role: '',
      facilities: []
    });
    setError('');
  };

  const handleNewUserChange = (e: any) => {
    setNewUser({ ...newUser, [e.target.id]: e.target.value });
  };

  const handleCreateUser = async (e: any) => {
    e.preventDefault();

    if (newUser.password !== newUser.passwordConfirmation) {
      setError('Passwords do not match');
    } else if (
      newUser.password.length < 8 ||
      !newUser.password.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/)
    ) {
      setError(
        'Password must be at least 8 characters, and contain at least one number and one special character'
      );
    } else {
      try {
        const response = await axios.post(`${WRITE_API_URL}/api/v1/admin/users`, newUser);
        if (response.status === 200) {
          setSuccess('User Created Successfully!');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        // @ts-ignore
        setError(error.response?.data?.message || 'Error creating user');
      }
    }
  };

  return (
    <div className="p-5">
      <div className="bg-[#FBFBFB] p-3 shadow-[1px_0_10px_1px_rgba(0,0,0,0.3)] text-sm">
        <div className="flex justify-between mb-2">
          <div className="w-full">
            <div className="flex gap-2 items-center mt-2">
              Filter By:
              <input
                type="search"
                className="border-slate-300 border-[1px] p-1 h-[38px] rounded"
                onChange={handleSearchChange}
                value={searchString}
                placeholder="Search"
              />
              {/* <Select
                options={createdAtOptions}
                isClearable
                placeholder="Created At"
                className="min-w-40"
                onChange={(selectedOption: any) => {
                  setCreatedAt(selectedOption?.value);
                }}
              /> */}
              <Select
                options={facilities}
                isSearchable
                isClearable
                isMulti
                placeholder="Facility"
                className="min-w-40"
                onChange={(selectedOptions: any) => {
                  setSelectedFacilities(
                    selectedOptions.map((option: any) => option.value)
                  );
                }}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <button
              className="bg-sky-600 hover:bg-sky-500 text-white p-2 h-[38px] rounded mb-2 whitespace-nowrap"
              onClick={() => setShowModal(true)}
            >
              New User
            </button>
          </div>
        </div>

        <table className="table-auto border-slate-300 border-[1px] w-full text-left">
          <thead className="border-red-400 border-b-[3px]">
            <tr>
              <th className="p-2">Email</th>
              <th className="p-2">Name</th>
              <th className="p-2">Role</th>
              <th className="p-2">Facilities</th>
              <th className="p-2">Created At</th>
            </tr>
          </thead>
          <tbody>
            {searchedUsers.map((user: any) => {
              return <Row key={user.id} user={user} />;
            })}
          </tbody>
        </table>
        {loading && (
          <div className="flex flex-col items-center mt-4">
            <CircularProgress />
            <p className="mt-2">Loading...</p>
          </div>
        )}
      </div>
      <Modal
        open={showModal}
        onClose={handleModalClose}
        className="flex justify-center items-center"
      >
        <div className="w-1/2 bg-white border p-5 h-2/3 relative overflow-y-scroll">
          <Close
            className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
            onClick={handleModalClose}
          />
          {!!success ? (
            <div className="flex flex-col items-center justify-center h-full">
              <Check className="text-green-500" />
              <p className="text-green-500 text-lg">{success}</p>
            </div>
          ) : (
            <form onSubmit={handleCreateUser}>
              <div>
                <h1 className="font-bold text-lg mb-2">New User</h1>
                {!!error && (
                  <p className="text-red-500 bg-red-100 border border-red-500 px-2 my-2 capitalize">
                    {error}
                  </p>
                )}
                <div className="flex flex-col mb-2">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    className="border-slate-300 border-[1px] p-1 h-[38px] rounded"
                    placeholder="Email"
                    onChange={handleNewUserChange}
                    required
                  />
                </div>
                <div className="flex flex-col mb-2">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    className="border-slate-300 border-[1px] p-1 h-[38px] rounded"
                    placeholder="Name"
                    onChange={handleNewUserChange}
                    required
                  />
                </div>
                <div className="flex flex-col mb-2">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    className="border-slate-300 border-[1px] p-1 h-[38px] rounded"
                    placeholder="Password"
                    onChange={handleNewUserChange}
                    required
                  />
                </div>
                <div className="flex flex-col mb-2">
                  <label htmlFor="passwordConfirmation">Password</label>
                  <input
                    type="password"
                    id="passwordConfirmation"
                    className="border-slate-300 border-[1px] p-1 h-[38px] rounded"
                    placeholder="Confirm Password"
                    onChange={handleNewUserChange}
                    required
                  />
                </div>
                <div className="flex flex-col mb-2">
                  <label htmlFor="role">Role</label>
                  <Select
                    options={roles}
                    placeholder="Role"
                    className="min-w-40"
                    onChange={(selectedOption: any) => {
                      setNewUser({ ...newUser, role: selectedOption.value });
                    }}
                    isSearchable={false}
                    required
                  />
                </div>
                <div className="flex flex-col mb-2">
                  <label htmlFor="facilities">Facilities</label>
                  <Select
                    options={facilities}
                    isSearchable
                    isMulti
                    placeholder="Facilities"
                    className="min-w-40"
                    onChange={(selectedOptions: any) => {
                      setNewUser({
                        ...newUser,
                        facilities: selectedOptions.map((option: any) => option.value)
                      });
                    }}
                    required
                  />
                </div>
                <button
                  className="bg-sky-600 text-white p-2 rounded hover:bg-sky-500"
                  type="submit"
                >
                  Create
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </div>
  );
};

const Row = ({ user }: { user: any }) => {
  const { facilities, createdat, email, username, role } = user;
  const handleClick = useLinkClickHandler(`/users/${user.id}`);
  let facilitiesStr = '';
  if (facilities.length === 1) {
    facilitiesStr = `${facilities[0].id} (${facilities[0].name})`;
  } else if (facilities.length > 1) {
    facilitiesStr = 'Multiple';
  }

  return (
    <tr
      onClick={(e: any) => handleClick(e)}
      className="hover:bg-[#f5f5f5] border-slate-200 border-b-[1px] cursor-pointer odd:bg-[#f9f9f9] last:border-b-0"
    >
      <td className="p-2">{email}</td>
      <td className="p-2">{username}</td>
      <td className="p-2">
        {!!user.approved ? (
          <span
            className={twMerge(
              'p-1 capitalize bg-slate-300 border border-slate-600 rounded mr-1 whitespace-nowrap last:mr-0',
              role.toLowerCase() === 'super admin'
                ? 'bg-emerald-300 border-emerald-600'
                : '',
              role.toLowerCase() === 'admin' ? 'bg-blue-300 border-blue-600' : ''
            )}
          >
            {role}
          </span>
        ) : (
          <span className="p-1 capitalize bg-red-300 border border-red-600 whitespace-nowrap rounded mr-1 last:mr-0">
            Deactivated
          </span>
        )}
      </td>
      <td className="p-2">{facilitiesStr}</td>
      <td className="p-2">
        {new Date(createdat).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: '2-digit',
          hour: 'numeric',
          minute: 'numeric'
        })}
      </td>
    </tr>
  );
};

export default Users;
