import axios from 'axios';
const READ_API_URL = process.env.REACT_APP_READ_API_URL;

export const exportShipments = async () => {
  const csv = [];
  const response = await axios.get(`${READ_API_URL}/api/v1/admin/shipments/export`);

  const shipments = response.data;
  csv.push(Object.keys(shipments[0]).join(','));
  shipments.forEach((shipment) => {
    // qoute wrap strings
    const row = Object.values(shipment).map((value) => {
      if (typeof value === 'string') {
        return '"' + value + '"';
      } else {
        return value;
      }
    });
    csv.push(row.join(','));
  });
  // create csv file
  var csvFile = new Blob([csv.join('\n')], { type: 'text/csv' });
  // create download link
  var downloadLink = document.createElement('a');
  downloadLink.download = 'export.csv';
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  downloadLink.click();

  return true;
};
