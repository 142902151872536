import { ContentCopy } from '@mui/icons-material';
import { useState } from 'react';
import { TimelineItem } from '../../types/Timeline';
import { CircularProgress } from '@mui/material';

interface TimelineProps {
  data: TimelineItem[];
  loading: boolean;
}

const Timeline = ({ data, loading }: TimelineProps) => {
  if (loading) {
    return (
      <div className="p-3">
        <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className="p-3">
      <table className="table-fixed w-full border-slate-300 border-[1px]">
        <thead>
          <tr className="underline">
            <th className="text-left p-2">Action</th>
            <th className="text-center p-2">Timestamp</th>
            <th className="text-right p-2">Status Bucket</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <Row key={index} item={item} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Row = ({ item }: { item: TimelineItem }) => {
  const { action, timestamp, status, image, filename, presigned_url, downloadable } =
    item;
  const [copied, setCopied] = useState(false);

  const openPdf = (e: any) => {
    const newTab = window.open(presigned_url, '_blank');
    if (newTab) {
      e.preventDefault();
      newTab.focus();
    }
  };

  const copyFilename = () => {
    // @ts-ignore
    navigator.clipboard.writeText(filename);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <tr className="border-slate-200 border-b-[1px] odd:bg-[#f9f9f9] last:border-b-0">
      {!!presigned_url && !!filename && (
        <td className="text-left p-2">
          <span>{action}</span>
          <span
            className="ml-2 hover:cursor-pointer"
            title="Copy Filename"
            onClick={copyFilename}
          >
            <ContentCopy fontSize="small" />
            {copied && <span className="text-green-500 animate-fade-out">Copied!</span>}
          </span>
          {downloadable ? (
            <button
              className="text-sky-600 underline hover:text-sky-500 max-w-full break-words block"
              onClick={openPdf}
            >
              {filename}
            </button>
          ) : (
            <span className="max-w-full break-words block">{filename}</span>
          )}
        </td>
      )}
      {!!image && !presigned_url && (
        <td className="text-left p-2">{<img src={image} alt="Action" />}</td>
      )}
      {!presigned_url && !image && <td className="text-left">{action}</td>}
      <td className="text-center p-2">
        {new Date(timestamp).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: '2-digit',
          hour: 'numeric',
          minute: 'numeric'
        })}
      </td>
      {/* @ts-ignore */}
      <td className="text-right p-2">{status}</td>
    </tr>
  );
};

export default Timeline;
