import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Place, LocalShipping, Info, Upload, Close } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { twMerge } from 'tailwind-merge';
import axios from 'axios';
import { Modal } from '@mui/material';
import { UserContext } from '../../App';
import Timeline from '../../components/Timeline/Timeline';

const READ_API_URL = process.env.REACT_APP_READ_API_URL;
const WRITE_API_URL = process.env.REACT_APP_WRITE_API_URL;

const userFromStorage = localStorage.getItem('user');
let allowUser = false;
if (!!userFromStorage) {
  const userEmail = JSON.parse(userFromStorage).email;
  if ( userEmail.split('@')[1] === 'freightroll.com') {
    allowUser = true;
  }
}

const Shipment = () => {
  // @ts-ignore
  const { user } = useContext(UserContext);
  const shipmentId = useParams<{ shipmentId: string }>().shipmentId;
  const [shipment, setShipment] = useState<any | null>(null);
  const [timelineLoading, setTimelineLoading] = useState(false);
  const [timelineData, setTimelineData] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState<any | null>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get(
        `${READ_API_URL}/api/v1/admin/shipments/${shipmentId}`
      );
      setShipment(response.data);
      setLoading(false);
    };

    if (!!user) {
      fetchData();
    }
  }, [shipmentId, user]);

  useEffect(() => {
    const fetchData = async () => {
      setTimelineLoading(true);
      const response = await axios.get(
        `${READ_API_URL}/api/v1/admin/shipments/${shipmentId}/get_timeline`
      );
      setTimelineData(response.data);
      setTimelineLoading(false);
    };

    if (!!shipment) {
      fetchData();
    }
  }, [shipmentId, shipment]);

  const handlePdfUpload = async () => {
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = async () => {
      setUploading(true);
      // @ts-ignore
      const base64 = reader.result.split(',')[1];
      try {
        await axios.post(
          `${WRITE_API_URL?.replace('web-btb', 'api-btb')}/api/v1/admin/upload_bol_pdf`,
          {
            bol_id: shipment.bol_id,
            pdf_base64: base64
          }
        );
        setUploadSuccess(true);
        setUploading(false);
      } catch (error) {
        console.error('Error uploading PDF:', error);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    setFile(event.dataTransfer.files[0]);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setFile(null);
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center mt-4 w-full">
        <CircularProgress />
        <p className="mt-2">Loading...</p>
      </div>
    );
  }

  if (!shipment) {
    return <div>Sorry, shipment not found!</div>;
  }

  return (
      <div className="p-5 w-full">
        <h1 className="text-2xl mb-3">Shipment # {shipment.tnumber}</h1>
        <div className="flex gap-5">
          <InfoCard
              title="Reference #s"
              icon={<Info className="text-[#5db1ff]"/>}
              color="#5db1ff"
              data={[
                {label: 'Shipment #', value: shipment.tnumber},
                {label: 'BOL #', value: shipment.tnumber}
                // { label: 'PO #', value: '09283' },
                // { label: 'Appointment #', value: '72390' }
              ]}
          />
          <InfoCard
              title="Ship From"
              icon={<Place/>}
              data={[{value: shipment.ship_from}]}
          />
          <InfoCard
              title="Carrier"
              color="#fb6e52"
              icon={<LocalShipping className="transform -scale-x-100 text-[#fb6e52]"/>}
              data={[{value: shipment.carrier_name}]}
          />
          <InfoCard
              title="Ship To"
              icon={<Place className="text-[#8cc474]"/>}
              color="#8cc474"
              data={[{value: shipment.ship_to}]}
          />
          <InfoCard
              title="Checked In"
              icon={<Place className="text-[#8cc474]"/>}
              color="#8cc474"
              data={[{value: shipment.check_in}]}
          />
        </div>
        <div className="mt-5">
          <ShipmentButton
              label="Upload BOL PDF"
              icon={<Upload fontSize="small"/>}
              onClick={() => setShowModal(true)}
          />
        </div>
        <div className="flex gap-5">
          <div className="shadow bg-[#FBFBFB] w-1/2 mt-5">
            <div className="p-2 shadow text-sm bg-white">Shipment Timeline</div>
            <Timeline data={timelineData} loading={timelineLoading}/>
          </div>
        </div>
        <div className="mt-5">
          {!shipment.check_out && <ShipmentButton
              label="Reset Driver Check-in"
              icon={<Upload fontSize="small"/>}
              onClick={async () => {
                if(window.confirm("Are you sure?")) {
                  await axios.post(
                      `${WRITE_API_URL}/api/v1/admin/reset_shipment`,
                      {
                          shipment_id: shipment.id,
                      }
                  );
                }
              }}
          />}
        </div>
        <div>
          {allowUser && (
              <table>
                <thead>
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(shipment).map(([key, value]) => (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>{JSON.stringify(value)}</td>
                    </tr>
                ))}
                </tbody>
              </table>
          )}
        </div>

        {showModal && (
            <Modal
                open={showModal}
                onClose={handleModalClose}
                className="flex justify-center items-center"
            >
              <div className="w-96 bg-white border p-5 min-h-48 relative flex flex-col">
                <Close
                    className="absolute top-4 right-4 cursor-pointer hover:text-slate-400"
                    onClick={handleModalClose}
                />
                <h1 className="text-xl mb-5">Upload BOL PDF</h1>
                {uploadSuccess ? (
                    <div className="bg-green-100 p-2 border-slate-300 border-[1px] text-sm mb-3">
                      PDF uploaded successfully!
                    </div>
                ) : (
                    <>
                      <input type="file" accept=".pdf" onChange={handleFileChange}/>
                      <div
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                          className="p-5 mt-3 border-dashed border-slate-300 border-2"
                      >
                        {file ? file.name : 'Or drop PDF file here'}
                      </div>
                      <button
                          className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded ml-auto mt-3"
                          onClick={handlePdfUpload}
                          disabled={uploading}
                      >
                        {uploading ? 'Uploading...' : 'Upload PDF'}
                      </button>
                    </>
                )}
              </div>
            </Modal>
        )}
      </div>
  );
};

interface InfoCardProps {
  title: string;
  icon: any;
  color?: string;
  data: {
    label?: string;
    value: string | number;
  }[];
}

const InfoCard = ({title, icon, color, data }: InfoCardProps) => {
  return (
    <div className="bg-[#FBFBFB] w-1/5 min-w-[200px] shadow border-slate-300 border-x-[1px] text-sm p-5">
      <div className="flex mb-3 items-center">
        <div className="mr-2">{icon}</div>
        <h3 className={`text-[${color}] text-xl`}>{title}</h3>
      </div>
      <div className="flex flex-col">
        {data.map((item, i) => {
          if (typeof item.value === 'boolean' && item.value) {
            item.value = 'Yes';
          }
          return (
            <div key={i} className="flex justify-between">
              {item.label && <span className="font-bold">{item.label}</span>}
              <span>{item.value}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface ShipmentButtonProps {
  label: string;
  icon: any;
  onClick: () => void;
  disabled?: boolean;
}

const ShipmentButton = ({ label, icon, onClick, disabled }: ShipmentButtonProps) => {
  const handleClick = () => {
    if (disabled) {
      return;
    }
    onClick();
  };

  return (
    <button
      className={twMerge(
        'border-slate-300 border-[1px] border-l-0 first:border-l-[1px] p-2 shadow bg-white hover:bg-[#e6e6e6] text-sm',
        disabled ? 'bg-[#f9f9f9] hover:bg-[#f9f9f9] text-gray-300 cursor-default' : ''
      )}
      onClick={handleClick}
    >
      {icon}
      <span className="ml-1">{label}</span>
    </button>
  );
};
export default Shipment;
