import axios from 'axios';
import { useEffect, useState, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { UserContext } from '../App';
const READ_API_URL = process.env.REACT_APP_READ_API_URL;

interface Facility {
  id: number;
  name: string;
}

const UnassginedSlotDrivers = () => {
  // @ts-ignore
  const { user } = useContext(UserContext);
  const [shipments, setShipments] = useState([]);
  const [facility, setFacility] = useState<Facility>();
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [daInterval, setDaInterval] = useState(12); // [12, 24, 48]

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${READ_API_URL}/api/v1/admin/facilities`);
      setFacilities(response.data);
      if (response.data.length > 0) {
        setFacility(response.data[0]);
      }
    };
    if (!!user) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    if (!facility?.id) {
      setShipments([]);
      return;
    }

    const fetchData = async () => {
      const response = await axios.get(
        `${READ_API_URL}/api/v1/admin/drivers/unassigned_slots?facility_id=${facility.id}&da_interval=${daInterval}`
      );
      setShipments(response.data);
    };
    if (!!user) {
      // Fetch Data on mount, and then every 30 seconds
      fetchData();
      const intervalId = setInterval(fetchData, 30000);
      return () => clearInterval(intervalId);
    }
  }, [user, facility, daInterval]);

  const options = facilities.map((facility) => ({
    value: facility,
    label: `(${facility.id}) ${facility.name}`
  }));

  const intervalOptions = [
    {
      value: 12,
      label: '12 Hours'
    },
    {
      value: 24,
      label: '24 Hours'
    },
    {
      value: 48,
      label: '48 Hours'
    },
    {
      value: 8760, // 1 year
      label: 'Max'
    }
  ];

  return (
    <div className="p-5">
      <div className="flex gap-4">
        <Select
          options={options}
          isSearchable={true}
          isClearable
          value={options.find((option) => option.value.id === facility?.id)}
          placeholder="Facilities"
          onChange={(selectedOption: any) => {
            setFacility(selectedOption?.value);
          }}
          className="max-w-80"
        />
        <Select
          options={intervalOptions}
          defaultValue={intervalOptions[0]}
          placeholder="Interval"
          className="max-w-80"
          onChange={(selectedOption: any) => {
            setDaInterval(selectedOption.value);
          }}
        />
      </div>
      <h1 className="text-2xl my-3">
        Drivers with Unassigned Slots
        {!!facility ? ` - (${facility.id}) ${facility.name}` : ''}
      </h1>
      <div className="flex gap-4 flex-wrap">
        {shipments.map((shipment: any) => (
          <Driver key={shipment.puid} shipment={shipment} />
        ))}
      </div>
    </div>
  );
};

const Driver = ({ shipment }: { shipment: any }) => {
  const {
    driver_name,
    puid,
    data,
    id,
    driver_phone_number,
    check_in_time,
    truck_number,
    trailer_number,
    metadata
  } = shipment;
  const [assigned, setAssigned] = useState(false);
  const [assignmentType, setAssignmentType] = useState('');
  const [assignmentNumber, setAssignmentNumber] = useState('');

  const calculateTimeElapsed = useCallback(() => {
    const now = new Date();
    const checkIn = new Date(check_in_time);
    // @ts-ignore
    const difference = now - checkIn;
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }, [check_in_time]);
  const [timeElapsed, setTimeElapsed] = useState(calculateTimeElapsed());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeElapsed(calculateTimeElapsed());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [calculateTimeElapsed]);

  const handleAssign = async () => {
    const payload = {
      shipment_id: id,
      facility_id: shipment.facility_of_origin_id,
      assignment_number: assignmentNumber,
      assignment_type: assignmentType,
      assigned_at: new Date().toISOString(),
      assigned_by: 'Admin'
    };
    console.log(payload);
    setAssigned(true);

    // const response = await axios.post(
    //   `${API_URL}/api/v1/admin/assign`,
    //   payload
    // );
    // console.log(response);
  };

  return (
    <Link to={`/shipments/${id}`} className="shadow bg-[#FBFBFB] p-3 w-80">
      <h2 className="font-bold text-lg">Driver: {driver_name}</h2>
      <p>
        <span className="font-bold">Phone Number:</span> {driver_phone_number}
      </p>
      <p>
        <span className="font-bold">TNumber:</span> {puid}
      </p>
      {!!data?.seal_number && (
        <p>
          <span className="font-bold">Seal Number:</span> {data.seal_number}
        </p>
      )}
      <p>
        <span className="font-bold">Truck Number:</span> {truck_number}
      </p>
      <p>
        <span className="font-bold">Trailer Number:</span> {trailer_number}
      </p>
      {!!metadata?.shipment_type && (
        <p>
          <span className="font-bold">Load Type:</span>{' '}
          {metadata.shipment_type === 'D' ? 'Drop' : 'Live'}
        </p>
      )}
      {!!data?.weight && (
        <p>
          <span className="font-bold">Weight:</span> {data.weight}
        </p>
      )}
      <p>
        <span className="font-bold">Check In Time:</span> {timeElapsed.days} days,{' '}
        {timeElapsed.hours} hours, {timeElapsed.minutes} minutes ago
      </p>
      {!!assigned ? (
        <div className="mt-4 text-green-700">
          <p>
            Assigned {assignmentNumber} to {assignmentType.replace('_', ' ')}!
          </p>
        </div>
      ) : (
        <div className="mt-4" onClick={(e: any) => e.preventDefault()}>
          <input
            type="text"
            className="border-slate-300 border-[1px] p-1 h-[38px] rounded w-full"
            placeholder="Assignment Number"
            onChange={(e) => setAssignmentNumber(e.target.value)}
          />
          <Select
            options={[
              {
                value: 'DOCK_DOOR',
                label: 'Dock Door'
              },
              {
                value: 'YARD_SPOT',
                label: 'Yard Spot'
              }
            ]}
            placeholder="Assignment Type"
            className="my-2"
            onChange={(selectedOption: any) => {
              setAssignmentType(selectedOption.value);
            }}
          />
          <button
            onClick={handleAssign}
            className="bg-sky-600 text-white p-2 rounded hover:bg-sky-500 float-right"
          >
            Assign
          </button>
        </div>
      )}
    </Link>
  );
};

export default UnassginedSlotDrivers;
