import { twMerge } from 'tailwind-merge';
import { NavLink } from 'react-router-dom';
import { LocalShipping, Person, ManageAccounts } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import logo from '../../assets/freight-roll-logo.png';

interface SidePanelProps {
  showing: boolean;
  showSlotlessDrivers: boolean;
  showUserManagement: boolean;
}

const SidePanel = ({
  showing,
  showSlotlessDrivers,
  showUserManagement
}: SidePanelProps) => {
  return (
    <div
      className={twMerge(
        'bg-[#323D48] text-white text-sm flex flex-col w-48 fixed h-screen',
        !showing ? 'hidden' : ''
      )}
    >
      <Link to="/shipments">
        <img src={logo} alt="FreightRoll Logo" className="mr-5" height="70" />
      </Link>
      <h2 className="text-[#979ba7] pb-1 p-5 w-[198px]">Dashboard</h2>
      <NavLink
        to="/shipments"
        className={({ isActive }) =>
          twMerge(
            'px-5 py-3 hover:bg-[#222A33] border-[#2dc3e8] border-l-4 hover:border-[#fb6e52]',
            isActive ? 'bg-[#222A33]' : ''
          )
        }
      >
        <LocalShipping className="mr-1 !h-5 transform -scale-x-100" />
        Shipments
      </NavLink>
      {!!showSlotlessDrivers && (
        <NavLink
          to="/drivers/unassigned_slot"
          className={({ isActive }) =>
            twMerge(
              'px-5 py-3 hover:bg-[#222A33] border-[#2dc3e8] border-l-4 hover:border-[#fb6e52]',
              isActive ? 'bg-[#222A33]' : ''
            )
          }
        >
          <Person className="mr-1 !h-5" />
          Slotless Drivers
        </NavLink>
      )}
      {!!showUserManagement && (
        <NavLink
          to="/users"
          className={({ isActive }) =>
            twMerge(
              'px-5 py-3 hover:bg-[#222A33] border-[#2dc3e8] border-l-4 hover:border-[#fb6e52] mt-auto',
              isActive ? 'bg-[#222A33]' : ''
            )
          }
        >
          <ManageAccounts className="mr-1 !h-5" />
          User Management
        </NavLink>
      )}
    </div>
  );
};

export default SidePanel;
