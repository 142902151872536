import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const WRITE_API_URL = process.env.REACT_APP_WRITE_API_URL;

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  let search = window.location.search;
  useEffect(() => {
    let searchParams = new URLSearchParams(search);
    setEmail(searchParams.get('email') || '');
    setToken(searchParams.get('token') || '');
  }, [search]);

  const handleReset = async () => {
    if (password !== passwordConfirmation) {
      setError('Passwords do not match');
      return;
    } else if (
      password.length < 8 ||
      !password.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/)
    ) {
      setError(
        'Password must be at least 8 characters, and contain at least one number and one special character'
      );
      return;
    } else if (error.length) {
      setError('');
    }

    try {
      await axios.post(`${WRITE_API_URL}/api/v1/admin/reset_password`, {
        email,
        password,
        token
      });

      setSuccess('Password Reset Successfully!');
    } catch (error) {
      console.error('Reset Password Failed', error);
      setError('Reset Password Failed');
    }
  };

  return (
    <div className="flex grow items-center justify-center">
      <div className="flex flex-col gap-4 bg-white p-8 shadow">
        {!!success ? (
          <>
            <p className="text-green-500">{success}</p>
            <Link
              className="text-sky-600 text-right hover:underline hover:text-sky-500"
              to="/login"
            >
              Login
            </Link>
          </>
        ) : (
          <>
            <h1 className="text-center">Reset Password</h1>
            <label>New Password:</label>
            <input
              className="border border-gray-400 p-2 bg-[#FBFBFB]"
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <label>Confirm New Password</label>
            <input
              className="border border-gray-400 p-2 bg-[#FBFBFB]"
              type="password"
              placeholder="Confirm Password"
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              value={passwordConfirmation}
            />
            {!!error && <p className="text-red-500 max-w-72">{error}</p>}
            <button
              className="bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
              onClick={handleReset}
            >
              Reset Password
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
