import { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { twMerge } from 'tailwind-merge';
import { ShipmentStatusType } from '../../types/Shipment';
import { Link, useLinkClickHandler } from 'react-router-dom';
// import * as Realm from 'realm-web';

const NewLabDemo = () => {
  const [shipments, setShipments] = useState<any[]>([]);
  const [statusFilter, setStatusFilter] = useState<ShipmentStatusType>(
    ShipmentStatusType.truckFound
  );
  const [filteredShipments, setFilteredShipments] = useState<any[]>([]);

  // const fetchData = async () => {
  //   const data = await fetch(
  //     'https://newlab-demo-api-48f138eaca60.herokuapp.com/shipments'
  //   );
  //   const json = await data.json();
  //   setShipments(json);
  // };

  // const app = new Realm.App({ id: 'feightroll-admin-app-nmsrv' });
  // useEffect(() => {
  //   const watchDB = async () => {
  //     const mongodb = app.currentUser?.mongoClient('mongodb-atlas');
  //     const collection = mongodb?.db('freightroll_admin').collection('shipments');

  //     if (collection) {
  //       for await (const change of collection.watch()) {
  //         fetchData();
  //       }
  //     }
  //   };

  //   watchDB();
  // }, []);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    const filteredShipments = shipments.filter(
      (shipment: any) => shipment.status === statusFilter
    );
    setFilteredShipments(filteredShipments);
  }, [statusFilter, shipments]);

  return (
    <div className="p-5 w-full">
      <div className="bg-[#FBFBFB] p-3 shadow-[1px_0_10px_1px_rgba(0,0,0,0.3)] text-sm">
        <div className="flex mb-2 items-center">
          Filter By:
          <button
            className={twMerge(
              'border-slate-300 border-[1px] p-2 shadow ml-1 hover:bg-[#e6e6e6]',
              statusFilter === ShipmentStatusType.truckFound
                ? 'bg-[#e6e6e6] hover:bg-[#d4d4d4] shadow-inner'
                : ''
            )}
            onClick={() => setStatusFilter(ShipmentStatusType.truckFound)}
          >
            New
          </button>
          <button
            className={twMerge(
              'border-slate-300 border-[1px] p-2 shadow border-l-0 hover:bg-[#e6e6e6]',
              statusFilter === ShipmentStatusType.canceled
                ? 'bg-[#e6e6e6] hover:bg-[#d4d4d4] shadow-inner'
                : ''
            )}
            onClick={() => setStatusFilter(ShipmentStatusType.canceled)}
          >
            Completed
          </button>
          <button
            className={twMerge(
              'border-slate-300 border-[1px] p-2 shadow border-l-0 hover:bg-[#e6e6e6]',
              statusFilter === ShipmentStatusType.canceled
                ? 'bg-[#e6e6e6] hover:bg-[#d4d4d4] shadow-inner'
                : ''
            )}
            onClick={() => setStatusFilter(ShipmentStatusType.canceled)}
          >
            Canceled
          </button>
          <Link
            to="/admin/addShipment"
            className="ml-auto bg-sky-600 hover:bg-sky-500 text-white p-2 rounded"
          >
            Add Shipment
          </Link>
        </div>

        <div>
          Search:
          <input type="search" className="border-slate-300 border-[1px] m-2 p-1"></input>
        </div>

        <table className="table-auto border-slate-300 border-[1px]">
          <thead className="border-red-400 border-b-[3px]">
            <tr>
              <th className="p-2">Ref #</th>
              <th className="p-2">Truck Registered</th>
              <th className="p-2">Implifi Truck #</th>
              <th className="p-2">OCR Trailer #</th>
              {/* <th className="p-2">OCR License #</th> */}
              <th className="p-2 whitespace-nowrap" title="Checked In">
                C-I
              </th>
              <th className="p-2" title="Inspected">
                Insp.
              </th>
              <th className="p-2" title="Drop Spot #">
                Dock Assign
              </th>
              <th className="p-2" title="Shipper Signature">
                Ship Sig
              </th>
              <th className="p-2" title="Driver Signature">
                Drv Sig
              </th>
              <th className="p-2 whitespace-nowrap" title="Checked Out">
                C-O
              </th>
              <th className="p-2">Ship From</th>
              <th className="p-2">Ship To</th>
              <th className="p-2">Est. Ship Date/Time (Local Time)</th>
              <th className="p-2">Carrier</th>
              <th className="p-2" title="Message">
                MSG
              </th>
              <th className="p-2">Photo</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {filteredShipments.map((shipment: any) => (
              <Row key={shipment.refNumber} shipment={shipment} />
            ))}
          </tbody>
        </table>

        <div className="flex justify-between mt-2">
          <button className="hover:text-slate-400">{'<'} Prev</button>
          <button className="hover:text-slate-400">1</button>
          <button className="hover:text-slate-400">Next {'>'}</button>
        </div>
      </div>
    </div>
  );
};

interface RowProps {
  shipment: any;
}

const Row = ({ shipment }: RowProps) => {
  const handleClick = useLinkClickHandler(`/admin/updateShipment/${shipment._id}`);

  return (
    <tr
      onClick={(e: any) => handleClick(e)}
      className="hover:bg-[#f5f5f5] border-slate-200 border-b-[1px] cursor-pointer odd:bg-[#f9f9f9] last:border-b-0"
    >
      <td className="p-2">{shipment.refNumber}</td>
      <td className="p-2">
        {shipment.truckRegistered ? (
          <CheckIcon className="text-green-500" />
        ) : (
          <ClearIcon className="text-red-400" />
        )}
      </td>
      <td className="p-2">
        {shipment.implifiTruckNumMatch ? <CheckIcon className="text-green-500" /> : null}
      </td>
      <td className="p-2">
        {shipment.ocrTrailerNumMatch ? <CheckIcon className="text-green-500" /> : null}
      </td>
      {/* <td className="p-2">
        {shipment.ocrLicenseNumMatch ? <CheckIcon className="text-green-500" /> : null}
      </td> */}
      <td className="p-2">
        {shipment.checkedIn ? <CheckIcon className="text-green-500" /> : null}
      </td>
      <td className="p-2">
        {shipment.inspected ? <CheckIcon className="text-green-500" /> : null}
      </td>
      <td className="p-2">
        {shipment.dockConfirmation ? (
          <span className="text-green-500">{shipment.dockConfirmation}</span>
        ) : null}
      </td>
      <td className="p-2">
        {shipment.shipperSignature ? <CheckIcon className="text-green-500" /> : null}
      </td>
      <td className="p-2">
        {shipment.driverSignature ? <CheckIcon className="text-green-500" /> : null}
      </td>
      <td className="p-2">
        {shipment.checkedOut ? <CheckIcon className="text-green-500" /> : null}
      </td>
      <td className="p-2">{shipment.shipFrom}</td>
      <td className="p-2">{shipment.shipTo}</td>
      <td className="p-2">
        {new Date(shipment.estimatedShipTimeLocal).toLocaleString('en-us')}
      </td>
      <td className="p-2">{shipment.carrier}</td>
      <td className="p-2">{shipment.messages?.length}</td>
      <td className="p-2">{shipment.photos?.length}</td>
    </tr>
  );
};

export default NewLabDemo;
